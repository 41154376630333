// Variables
$accent: rgb(46, 119, 230);
$accent_text_color: #fff;

$secondary_text_color: #555;
$tertiary_text_color: #888;

// Functions

// Quick mixin to add styles on focus and hover (where hover is supported). This
// ensures that you don't get bizarre lingering hover effects on iOS.
@mixin focus_and_hover {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }

    &:focus {
        @content;
    }
}

// Rules

* {
    box-sizing: border-box;
}

body {
    // Default font
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0 0 1rem 0;
    padding: 0 1rem;
}

h1 {
    font-size: 2rem;
    margin: 0.5rem 0;
}

h2 {
    margin: 0.5rem 0;
}

.main {
    max-width: 40rem;
    margin: 0 auto;
}

.title {
    text-align: center;
}

.logo {
    $max-size: 40vh;
    max-width: $max-size;
    max-height: $max-size;
    margin: 0 auto;
    display: block;
}

.letter {
    font-size: 10rem;
    text-align: center;
}

.phrase {
    text-align: center;
    font-size: 1.5rem;
}

.guesses {
    /* https://www.client9.com/css-system-font-stack-monospace-v2/ */
    font-family:
        "SFMono-Regular",
        Consolas,
        "Liberation Mono",
        Menlo,
        Courier,
        monospace;
}

.guess {
    // font-weight: bold;
    white-space: pre;
}

.truth {
    color: red;
    white-space: pre;
}

.status {
    color: $secondary_text_color;
    font-size: 1.5rem;
    margin: 0.5rem 0;
}

.startButton,
.stopButton {
    display: block;

    width: 100%;
    $bg: #e0e0e0;
    background: $bg;
    padding: 1rem;

    font-size: 3rem;
    margin: 0 auto;
    border: none;
    border-radius: 1rem;

    cursor: pointer;
    transition: all 0.3s cubic-bezier(0,.71,.24,1.35);

    @include focus_and_hover {
        background: darken($bg, 10%);
    }

    // This is annoying if it applies when you tab to things, so only apply on
    // hover.
    @media (hover: hover) {
        &:hover {
            transform: scale(1.05);
        }
    }

    // Replace the focus outline
    &:focus {
        outline: none;
        box-shadow: inset 0 0 0 3px #000;
    }

    &:active {
        transform: scale(0.95);
        background: darken($bg, 20%);
    }
}

    .startButton {
        background: $accent;
        color: $accent_text_color;

        margin: 1rem 0;

        @include focus_and_hover {
            background: lighten($accent, 10%);
        }

        &:active {
            background: darken($accent, 10%);
        }
    }

    // Put the stop button in the corner if we're using a touch screen (which
    // will probably have a touch keyboard at the bottom of the screen).
    @media (pointer: coarse) {
        .stopButton {
            position: fixed;
            top: 1rem;
            right: 1rem;
            width: auto;
        }
    }

    .stopButton {
        // Force the button to be a square.
        $size: 5rem;
        height: $size;
        width: $size;
        line-height: 0;

        border-radius: $size / 2;
    }

.morseInput {
    width: 100%;
    border: 2px solid;

    font-size: 3rem;
}
